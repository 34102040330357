
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { searchInsurancePayments } from "@/api/insurancePayment.api";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { updateInsurancePayment } from "@/api/insurancePayment.api";
import { searchClaimsForPayment } from "@/api/claim.api";
import { useStore } from "vuex";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  selectedHeader: string;
  dict: {
    paymentTypes: any[];
    datesList: { keyword: number; name: string }[];
    claimsActionCodes: StatusCode[];
  };
  filter: {
    dateType: number;
    startDate: Date | string | null;
    endDate: Date | string | null;
    paymentType: number[] | null;
    amountFrom: number | null;
    amountTo: number | null;
  };
  showInactive: boolean;
  modalInsurancePaymentId;
  modal;
}
export default defineComponent({
  name: "InsuranceTransactionsList",
  components: {
    PaginationUi,
    Multiselect,
    DecimalInput,
    NoteComponent,
    DateFloatComponent,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const orderList = [
      {
        name: "Transaction ID",
        key: "insurancePaymentId",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payer",
        key: "payerId",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payment Type",
        key: "paymentType",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payment Date",
        key: "paymentDate",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Entry Date",
        key: "createdOn",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Deposit Date",
        key: "depositDate",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Amount",
        key: "amount",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Unapplied Credit",
        key: "unappliedCredit",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Remaining to Post",
        key: "remainingToPost",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Check/Remittance #",
        key: "checkRemittanceNumber",
        isFilter: true,
        keyword: undefined,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: false,
      showInactive: false,
      selectedHeader: "inProgress",
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      filter: {
        dateType: 1,
        startDate: null,
        endDate: null,
        paymentType: null,
        amountFrom: null,
        amountTo: null,
      },
      dict: {
        paymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
        datesList: [
          { keyword: 1, name: "Deposit Date" },
          { keyword: 2, name: "Payment Date" },
        ],
        claimsActionCodes: [],
      },
      modalInsurancePaymentId: null,
      modal: false,
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "default";
      data.IsAdvancedOrderBy = true;
      data.isDecr = false;
      data.filter = {
        dateType: 1,
        startDate: null,
        endDate: null,
        paymentType: null,
        amountFrom: null,
        amountTo: null,
      };

      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Payment Management");

      data.selectedHeader = (route.query.header as string) ?? "inProgress";
      data.dict.claimsActionCodes = store.getters.allClaimActions.sort((a, b) =>
        a.description.localeCompare(b.description)
      );

      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.map((item) => {
            return item.key;
          }),
          keyword: data.keyword,
        },
        DateType: data.filter.dateType,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        PaymentType: data.filter.paymentType,
        AmountFrom: data.filter.amountFrom,
        AmountTo: data.filter.amountTo,
        IsPosted: data.selectedHeader === "posted",
      };

      const res = await searchInsurancePayments(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    function newInsurancePayment() {
      router.push({ path: "insurancePayment" });
    }

    function newInsurancePaymentRefund() {
      router.push({ path: "insurancePaymentRefund" });
    }

    async function updateDepositDate(item) {
      await updateInsurancePayment(item);
    }

    function selectItem(item) {
      if (item.type == 1) {
        router.push({
          path: "/insurancePaymentRefund/" + item.id,
        });
      } else {
        router.push({
          path: "/insurancePayment/" + item.id,
        });
      }
    }

    async function loadClaimsForPayment(payment) {
      // don't need to re-load these claims
      if (payment.claims) {
        return;
      }

      let request = {
        orderBy: ["claimId"],
        currentInsurancePaymentId: payment.id,
        hasPayment: true,
        alreadyDistributed: true,
      };

      const res = await searchClaimsForPayment(request);
      payment.claims = res;

      for (let claim of payment.claims) {
        claim.totalAllowed = totalAllowed(claim, payment.id);
        claim.totalPayments = totalPayments(claim, payment.id);
        claim.totalAdjustment = totalAdjustment(claim, payment.id);
        claim.totalResponsibility = totalResponsibility(claim, payment.id);
      }
    }

    function totalAllowed(claim, paymentId) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == paymentId) {
              sum += dist.allowed;
            }
          }
        }
      }

      return sum;
    }

    function totalPayments(claim, paymentId) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == paymentId) {
              sum += dist.payment;
            }
          }
        }
      }

      return sum;
    }

    function totalAdjustment(claim, paymentId) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == paymentId) {
              sum += dist.adjustment;
            }
          }
        }
      }

      return sum;
    }

    function totalResponsibility(claim, paymentId) {
      let sum = 0.0;

      for (let line of claim.claimLineItems) {
        if (line.distributions) {
          for (let dist of line.distributions) {
            if (dist.insurancePaymentId == paymentId) {
              sum += dist.patientResponsibility;
            }
          }
        }
      }

      return sum;
    }

    function changeHeader(header) {
      data.selectedHeader = header;
      search();

      const currentRoute = route.fullPath;
      router.replace({
        path: currentRoute,
        query: { header },
      });
    }

    function getActionHeader(code) {
      if (data.dict.claimsActionCodes) {
        const status = data.dict.claimsActionCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    function selectNotes(payment) {
      data.modalInsurancePaymentId = payment.id;
      data.modal = true;
    }

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      getNow,
      newInsurancePayment,
      newInsurancePaymentRefund,
      updateDepositDate,
      selectItem,
      loadClaimsForPayment,
      changeHeader,
      getActionHeader,
      selectNotes,
    };
  },
});
